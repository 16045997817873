import "@/styles/globals.scss";
import {AppProps} from "next/app";
import React, {useEffect} from "react";
import {useRouter} from "next/router";
import Script from "next/script";
import Image from "next/image";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

function ArkeFitness({Component, pageProps}: AppProps) {
  const router = useRouter();
  useEffect(() => {}, [router.events]);

  return (
    <>
      {/* GTM */}
      {process.env.NODE_ENV === "production" && (
        <>
          <Script id="dl" strategy="lazyOnload">
            window.dataLayer = window.dataLayer || [];
          </Script>
          <Script id="gtm" strategy="lazyOnload">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PT485L4P');`}
          </Script>
        </>
      )}

      <Component {...pageProps} />

      <a
        href="https://wa.me/message/AESXYUNFR6NNK1"
        className="floating-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src="/images/brand-whatsapp-white.svg" height={32} width={32} />
      </a>
    </>
  );
}

export default ArkeFitness;
